

/*
    HTML5
    ---
    Default HTML5 CSS declarations
*/
* {
    box-sizing: border-box;
    transition: opacity 300ms, transform 300ms, color 300ms, background-color 300ms;
    &:focus {
        outline: none;
    }
}
a {
    color: my-color(hilight-alt);
    display: inline-block;
    // font-family: my-font-family(lead);
    text-decoration: none;
    &:not([class]),
    &.link {
        position: relative;
        &::before,
        &::after {
            content: '';
            display: block;
            position: absolute;
            height: 0.4em;
            width: calc(100% + 0.3em);
            background-color: my-color(hilight-alt);
            opacity: 0.1;
            bottom: 0em;
            left: -0.3em;
            transition: width 300ms, height 300ms, transform 300ms, bottom 300ms, opacity 300ms;
            transform: skewX(-40deg) rotateX(0deg);
            z-index: 0;
            transform-origin: bottom;
        }
        &::after {
            border-bottom: my-border(thin-solid-hilight-alt);
            opacity: 1;
            height: 0;
        } 
    }
    &:hover {
        // color: my-color(hilight);
        &:not([class]),
        &.link {
            position: relative;
            &::before {
                transform: skewX(0deg) rotateX(0deg);
                height: 100%;
                width: calc(100% + 0.6em);
            } 
            &::after {
                bottom: 70%;
                opacity: 0;
            } 
        }
    }
}
body {
    font-family: my-font-family(lead);
    font-size: my-font-size(md);
    color: my-color(primary);
    background-color: my-color(primary-max);
    line-height: 1.6em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
em {
    font-family: my-font-family(lead-em);
    font-style: normal; /* otherwise it makes too much italic */
}
strong {
    font-family: my-font-family(lead-strong);
}
ul {
    padding-left: my-spacing(md);
}
h1 {
    font-size: my-font-size(xxl);
    font-family: my-font-family(headline);
    // font-weight: normal;
    line-height: initial;
}
h2 {
    font-size: my-font-size(xl);
    font-family: my-font-family(headline);
    // font-weight: normal;
    line-height: initial;
}
h3 {
    font-size: my-font-size(lg);
    font-family: my-font-family(lead-strong);
    // font-weight: normal;
    line-height: initial;
}
h4,
h5,
h6 {
    font-family: my-font-family(lead-strong);
    line-height: initial;
}
img {
    max-width: 100%;
    max-height: 100%;
}
pre:not([class]) {
    font-size: my-font-size(sm);
    background-color: my-color(primary-edge);
    padding: my-spacing(sm);
}
code {
    background-color: my-color(primary-edge);
    padding-right: my-spacing(xxs);
    letter-spacing: -0.015em;
    font-family: my-font-family('monospace');
    &:not([class]) {
        display: inline-block;
        padding-left: my-spacing(xxs);
        padding-right: my-spacing(xxs);
        font-size: my-font-size(sm);
        position: relative;
        background-color: my-color(primary-edge);
        word-wrap: break-word;
        border-radius: 5px;
        box-shadow: 0px 2px 5px lightgrey;
    }
}
blockquote { 
    margin: my-spacing(lg, none);
    position: relative;
    padding: my-spacing(lg);
    font-family: my-font-family(lead-em);
    background-color: my-color(primary-edge);
    &::before {
        content: '"';
        position: absolute;
        display: block;
        line-height: 0;
        top: 30px;
        left: 10px;
        color: my-color(primary-alt);
        font-family: my-font-family(headline);
        font-size: 128px;
        -webkit-text-stroke: 5px my-color(primary-max);
    }
    &>blockquote {
        border: my-border(thin-solid-alt);
    }
    code {
        white-space: initial;
    }
}
table {
    border-collapse: collapse;
    width: 100%;
    thead {
        th {
            text-align: left;
            padding: my-spacing(xs);
            border-bottom: my-border(large-solid);
            font-size: my-font-size(sm);
            font-family: my-font-family(headline);
            line-height: 1.6em;
        }
    }
    tbody {
        td {
            padding: my-spacing(xs);
            border-bottom: my-border(large-solid-edge);
            font-size: my-font-size(sm);
            line-height: 1.5em;
            code {
                // word-break: break-all;
                &:not([class]) {
                    font-size: my-font-size(sm);
                }
            }
        }
    }
}
hr {
    border: none;
    border-top: my-border(thin-dashed);
}
iframe {
    max-width: 100%;
}
@include my-screen-size(sm) {
    body {
        font-size: my-font-size(sm);
    }
    h1 {
        font-size: my-font-size(xl);
    }
    h2 {
        font-size: my-font-size(lg);
    }
    h3 {
        font-size: my-font-size(md);
    }
    pre {
        font-size: my-font-size(xs);
    }
    blockquote { 
        margin: my-spacing(md, none);
        padding: my-spacing(md);
    }
    code {
        &:not([class]) {
            font-size: my-font-size(sm);
        }
    }
    table {
        thead {
            display: none;
        }
        tbody {
            tr,
            td {
                display: block;
                width: 100%;
            }
            tr {
                margin-bottom: my-spacing(sm);
                border-left: my-border(large-solid-edge);
                border-right: my-border(large-solid-edge);
                border-top: my-border(large-solid-edge);
            }
            td {
                position: relative;
                &::after {
                    content: attr(data-title);
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    font-size: my-font-size(xxs);
                    text-transform: uppercase;
                    white-space: nowrap;
                    background-color: my-color(primary-edge);
                    line-height: 1em;
                    padding: my-spacing(none, xxs);
                    font-family: my-font-family('monospace');
                }
            }
        }
    }
}