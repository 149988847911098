

/*
    GENERIC COMPONENT
    Briks generic component
    ---
*/

@if $briks-components-prefix != '' 
and $briks-components-prefix != false 
and $briks-modifiers-prefix != '' 
and $briks-modifiers-prefix != false 
and length($briks-components-generic) > 0 {
    // Iterate generic components list
    @each $component_name, $component_data in $briks-components-generic {
        // Component must be enabled
        @if map-get($component_data, enabled) == true {
            // Optional selector for defaults
            $optional_selector_defaults: '';
            @if type-of(map-get(map-get($component_data, defaults), selector)) == string {
                $optional_selector_defaults: map-get(map-get($component_data, defaults), selector);
            }
            // Component's class name
            .#{$briks-components-prefix}#{$component_name}#{unquote($optional_selector_defaults)} {
                // Iterate component default properties
                // If component defaults are set
                @if type-of(map-get($component_data, defaults)) == map and length(map-get($component_data, defaults)) > 0 {
                    @each $property, $value in map-get($component_data, defaults) {
                        // Default pseudo classes
                        @if $property == 'pseudo-classes' and type-of($value) == map {
                            @each $pseudo_class_name, $pseudo_class_data in $value {
                                @if type-of($pseudo_class_data) == map {
                                    // Optional selector for this pseudo-class on defaults
                                    $optional_selector_pseudo_class: '';
                                    @if type-of(map-get($pseudo_class_data, selector)) == string {
                                        $optional_selector_pseudo_class: map-get($pseudo_class_data, selector);
                                    }
                                    // Pseudo class
                                    &:#{$pseudo_class_name}#{unquote($optional_selector_pseudo_class)} {
                                        @each $pseudo_class_property, $pseudo_class_value in $pseudo_class_data {
                                            @if $pseudo_class_property == 'pseudo-elements' and type-of($pseudo_class_value) == map {
                                                // If pseudo class is combined with pseudo element (for example :hover::after)
                                                @each $pseudo_element_name, $pseudo_element_names_and_values in $pseudo_class_value {
                                                    // Optional selector for this pseudo-element on this pseudo-class on defaults
                                                    $optional_selector_pseudo_element: '';
                                                    @if type-of(map-get($pseudo_element_names_and_values, selector)) == string {
                                                        $optional_selector_pseudo_element: map-get($pseudo_element_names_and_values, selector);
                                                    }
                                                    // Pseudo element
                                                    &#{unquote($optional_selector_pseudo_element)}::#{$pseudo_element_name} {
                                                        @each $pseudo_element_property, $pseudo_element_value in $pseudo_element_names_and_values {
                                                            // Unquote value excepting 'content' property
                                                            @if type-of($pseudo_element_value) == 'string' and $pseudo_element_property != 'content' {
                                                                $pseudo_element_value: unquote($pseudo_element_value);
                                                            }
                                                            // Force quote for content property
                                                            @if ($pseudo_element_property == 'content') {
                                                                $pseudo_element_value: quote($pseudo_element_value);
                                                            }
                                                            @if ($pseudo_element_property != 'selector') {
                                                                #{$pseudo_element_property}: $pseudo_element_value;
                                                            }
                                                        }
                                                    }
                                                }
                                            } @else {
                                                // Standard CSS property
                                                @if type-of($pseudo_class_value) == 'string' {
                                                    $pseudo_class_value: unquote($pseudo_class_value);
                                                }
                                                @if ($pseudo_class_property != 'selector') {
                                                    #{$pseudo_class_property}: $pseudo_class_value;
                                                }
                                            }
                                        }
                                    }
                                }
                            } 
                        } 
                        // Default pseudo elements
                        @else if $property == 'pseudo-elements' and type-of($value) == map {
                            @each $pseudo_element_name, $pseudo_element_names_and_values in $value {
                                @if type-of($pseudo_element_names_and_values) == map {
                                    // Optional selector for this pseudo-element on defaults
                                    $optional_selector_pseudo_element: '';
                                    @if type-of(map-get($pseudo_element_names_and_values, selector)) == string {
                                        $optional_selector_pseudo_element: map-get($pseudo_element_names_and_values, selector);
                                    }
                                    // Pseudo element
                                    &#{unquote($optional_selector_pseudo_element)}::#{$pseudo_element_name} {
                                        @each $pseudo_element_property, $pseudo_element_value in $pseudo_element_names_and_values {
                                            // Unquote value excepting 'content' property
                                            @if type-of($pseudo_element_value) == 'string' and $pseudo_element_property != 'content' {
                                                $pseudo_element_value: unquote($pseudo_element_value);
                                            }
                                            // Force quote for content property
                                            @if ($pseudo_element_property == 'content') {
                                                $pseudo_element_value: quote($pseudo_element_value);
                                            }
                                            @if ($pseudo_element_property != 'selector') {
                                                #{$pseudo_element_property}: $pseudo_element_value;
                                            }
                                        }
                                    }
                                }
                            } 
                        } 
                        // Default properties and values
                        @else {
                            @if type-of($value) != map {
                                @if type-of($value) == 'string' {
                                    $value: unquote($value);
                                }
                                @if ($property != 'selector') {
                                    #{$property}: $value;
                                }
                            }
                        }
                    }
                }
                // If modifiers are set
                @if type-of(map-get($component_data, modifiers)) == map and length(map-get($component_data, modifiers)) > 0 {
                    // Iterate component modifiers properties
                    @each $modifier_name, $modifier_data in map-get($component_data, modifiers) {
                        // Optional selector for this modifier
                        $optional_selector_modifier: '';
                        @if type-of(map-get($modifier_data, selector)) == string {
                            $optional_selector_modifier: map-get($modifier_data, selector);
                        }
                        &.#{$briks-modifiers-prefix}#{$modifier_name}#{unquote($optional_selector_modifier)} {
                            @each $property, $value in $modifier_data {
                                // Modifier pseudo classes
                                @if $property == 'pseudo-classes' and type-of($value) == map {
                                    @each $pseudo_class_name, $pseudo_class_data in $value {
                                        // Optional selector for this pseudo-class on this modifier
                                        $optional_selector_modifier_pseudo_class: '';
                                        @if type-of(map-get($pseudo_class_data, selector)) == string {
                                            $optional_selector_modifier_pseudo_class: map-get($pseudo_class_data, selector);
                                        }
                                        @if type-of($pseudo_class_data) == map {
                                            // Modifier pseudo class
                                            &:#{$pseudo_class_name}#{unquote($optional_selector_modifier_pseudo_class)} {
                                                @each $pseudo_class_property, $pseudo_class_value in $pseudo_class_data {
                                                    @if $pseudo_class_property == 'pseudo-elements' and type-of($pseudo_class_value) == map {
                                                        // If pseudo class is combined with pseudo element (for example :hover::after)
                                                        @each $pseudo_element_name, $pseudo_element_names_and_values in $pseudo_class_value {
                                                            // Optional selector for this pseudo-element for this pseudo-class on this modifier
                                                            $optional_selector_modifier_pseudo_class_pseudo_element: '';
                                                            @if type-of(map-get($pseudo_element_names_and_values, selector)) == string {
                                                                $optional_selector_modifier_pseudo_class_pseudo_element: map-get($pseudo_element_names_and_values, selector);
                                                            }
                                                            // Modifier pseudo element
                                                            &#{unquote($optional_selector_modifier_pseudo_class_pseudo_element)}::#{$pseudo_element_name} {
                                                                @each $pseudo_element_property, $pseudo_element_value in $pseudo_element_names_and_values {
                                                                    // Unquote value excepting 'content' property
                                                                    @if type-of($pseudo_element_value) == 'string' and $pseudo_element_property != 'content' {
                                                                        $pseudo_element_value: unquote($pseudo_element_value);
                                                                    }
                                                                    // Force quote for content property
                                                                    @if ($pseudo_element_property == 'content') {
                                                                        $pseudo_element_value: quote($pseudo_element_value);
                                                                    }
                                                                    @if ($pseudo_element_property != 'selector') {
                                                                        #{$pseudo_element_property}: $pseudo_element_value;
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    } @else {
                                                        // Standard CSS property
                                                        @if type-of($pseudo_class_value) == 'string' {
                                                            $pseudo_class_value: unquote($pseudo_class_value);
                                                        }
                                                        @if ($pseudo_class_property != 'selector') {
                                                            #{$pseudo_class_property}: $pseudo_class_value;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    } 
                                } 
                                // Modifier pseudo elements
                                @else if $property == 'pseudo-elements' and type-of($value) == map {
                                    @each $pseudo_element_name, $pseudo_element_names_and_values in $value {
                                        @if type-of($pseudo_element_names_and_values) == map {
                                            // Optional selector for this pseudo-element for this modifier
                                            $optional_selector_modifier_pseudo_element: '';
                                            @if type-of(map-get($pseudo_element_names_and_values, selector)) == string {
                                                $optional_selector_modifier_pseudo_element: map-get($pseudo_element_names_and_values, selector);
                                            }
                                            // Modifier pseudo element
                                            &#{unquote($optional_selector_modifier_pseudo_element)}::#{$pseudo_element_name} {
                                                @each $pseudo_element_property, $pseudo_element_value in $pseudo_element_names_and_values {
                                                    // Unquote value excepting 'content' property
                                                    @if type-of($pseudo_element_value) == 'string' and $pseudo_element_property != 'content' {
                                                        $pseudo_element_value: unquote($pseudo_element_value);
                                                    }
                                                    // Force quote for content property
                                                    @if ($pseudo_element_property == 'content') {
                                                        $pseudo_element_value: quote($pseudo_element_value);
                                                    }
                                                    @if ($pseudo_element_property != 'selector') {
                                                        #{$pseudo_element_property}: $pseudo_element_value;
                                                    }
                                                }
                                            }
                                        }
                                    } 
                                } 
                                // Modifier properties and values
                                @else {
                                    @if type-of($value) != map {
                                        @if type-of($value) == 'string' {
                                            $value: unquote($value);
                                        }
                                        @if ($property != 'selector') {
                                            #{$property}: $value;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                // Responsive
                @if map-get($component_data, responsive) == true {
                    @each $screen, $value in $briks-screen-sizes {
                        @include my-screen-size($screen) {
                            // If modifiers are set
                            @if type-of(map-get($component_data, modifiers)) == map and length(map-get($component_data, modifiers)) > 0 {
                                // Iterate component modifiers properties
                                @each $modifier_name, $modifier_data in map-get($component_data, modifiers) {
                                    // Optional selector for defaults
                                    $optional_selector_defaults: '';
                                    @if type-of(map-get(map-get($component_data, defaults), selector)) == string {
                                        $optional_selector_defaults: map-get(map-get($component_data, defaults), selector);
                                    }
                                    &.#{$briks-modifiers-prefix}#{$modifier_name}#{$briks-responsive-prefix}#{$screen}#{unquote($optional_selector_defaults)},
                                    &[#{$briks-modifiers-prefix}#{$modifier_name}*="#{$screen}"]#{unquote($optional_selector_defaults)} {
                                        @each $property, $value in $modifier_data {
                                            // Responsive modifier pseudo classes
                                            @if $property == 'pseudo-classes' and type-of($value) == map {
                                                @each $pseudo_class_name, $pseudo_class_data in $value {
                                                    @if type-of($pseudo_class_data) == map {
                                                        // Optional selector for this pseudo-class on defaults
                                                        $optional_selector_pseudo_class: '';
                                                        @if type-of(map-get($pseudo_class_data, selector)) == string {
                                                            $optional_selector_pseudo_class: map-get($pseudo_class_data, selector);
                                                        }
                                                        // Responsive modifier pseudo class
                                                        &:#{$pseudo_class_name}#{unquote($optional_selector_pseudo_class)} {
                                                            @each $pseudo_class_property, $pseudo_class_value in $pseudo_class_data {
                                                                @if $pseudo_class_property == 'pseudo-elements' and type-of($pseudo_class_value) == map {
                                                                    // If pseudo class is combined with pseudo element (for example :hover::after)
                                                                    @each $pseudo_element_name, $pseudo_element_names_and_values in $pseudo_class_value {
                                                                        // Optional selector for this pseudo-element on this pseudo-class on defaults
                                                                        $optional_selector_pseudo_element: '';
                                                                        @if type-of(map-get($pseudo_element_names_and_values, selector)) == string {
                                                                            $optional_selector_pseudo_element: map-get($pseudo_element_names_and_values, selector);
                                                                        }
                                                                        // Responsive modifier pseudo element
                                                                        &#{unquote($optional_selector_pseudo_element)}::#{$pseudo_element_name} {
                                                                            @each $pseudo_element_property, $pseudo_element_value in $pseudo_element_names_and_values {
                                                                                // Unquote value excepting 'content' property
                                                                                @if type-of($pseudo_element_value) == 'string' and $pseudo_element_property != 'content' {
                                                                                    $pseudo_element_value: unquote($pseudo_element_value);
                                                                                }
                                                                                // Force quote for content property
                                                                                @if ($pseudo_element_property == 'content') {
                                                                                    $pseudo_element_value: quote($pseudo_element_value);
                                                                                }
                                                                                @if ($pseudo_element_property != 'selector') {
                                                                                    #{$pseudo_element_property}: $pseudo_element_value;
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                } @else {
                                                                    // Standard CSS property
                                                                    @if type-of($pseudo_class_value) == 'string' {
                                                                        $pseudo_class_value: unquote($pseudo_class_value);
                                                                    }
                                                                    @if ($pseudo_class_property != 'selector') {
                                                                        #{$pseudo_class_property}: $pseudo_class_value;
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                } 
                                            } 
                                            // Responsive modifier pseudo elements
                                            @else if $property == 'pseudo-elements' and type-of($value) == map {
                                                @each $pseudo_element_name, $pseudo_element_names_and_values in $value {
                                                    @if type-of($pseudo_element_names_and_values) == map {
                                                        // Optional selector for this pseudo-element on defaults
                                                        $optional_selector_pseudo_element: '';
                                                        @if type-of(map-get($pseudo_element_names_and_values, selector)) == string {
                                                            $optional_selector_pseudo_element: map-get($pseudo_element_names_and_values, selector);
                                                        }
                                                        // Responsive modifier pseudo element
                                                        &#{unquote($optional_selector_pseudo_element)}::#{$pseudo_element_name} {
                                                            @each $pseudo_element_property, $pseudo_element_value in $pseudo_element_names_and_values {
                                                                // Unquote value excepting 'content' property
                                                                @if type-of($pseudo_element_value) == 'string' and $pseudo_element_property != 'content' {
                                                                    $pseudo_element_value: unquote($pseudo_element_value);
                                                                }
                                                                // Force quote for content property
                                                                @if ($pseudo_element_property == 'content') {
                                                                    $pseudo_element_value: quote($pseudo_element_value);
                                                                }
                                                                @if ($pseudo_element_property != 'selector') {
                                                                    #{$pseudo_element_property}: $pseudo_element_value;
                                                                }
                                                            }
                                                        }
                                                    }
                                                } 
                                            } 
                                            // Responsive modifier properties and values
                                            @else {
                                                @if type-of($value) != map {
                                                    @if type-of($value) == 'string' {
                                                        $value: unquote($value);
                                                    }
                                                    @if ($property != 'selector') {
                                                        #{$property}: $value;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
