
/*
    GRID NOWRAP
    ---
    Feature of Briks grid component
*/
@if $briks-components__grid__enabled 
and $briks-components-prefix != '' 
and $briks-components-prefix != false 
and $briks-components__grid__name != '' 
and $briks-components__grid__name != false 
and $briks-components__grid__nowrap-name != false 
and $briks-components__grid__nowrap-name != '' {
    // Grid component
    .#{$briks-components-prefix}#{$briks-components__grid__name} {
        &.#{$briks-modifiers-prefix}#{$briks-components__grid__nowrap-name} {
            flex-wrap: nowrap;
        }
        @if $briks-components__grid__nowrap-responsive {
            @each $screen, $value in $briks-screen-sizes {
                @include my-screen-size($screen) {
                    &.#{$briks-modifiers-prefix}#{$briks-components__grid__nowrap-name}#{$briks-responsive-prefix}#{$screen},
                    &[#{$briks-modifiers-prefix}#{$briks-components__grid__nowrap-name}*="#{$screen}"] {
                        flex-wrap: nowrap;
                    }
                }
            }
        }
    }
}