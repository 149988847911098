
/*
    GRID ASPECT RATIOS
    ---
    Feature of Briks grid component
*/
@if $briks-components__grid__enabled 
and $briks-components-prefix != '' 
and $briks-components-prefix != false 
and $briks-components__grid__name != '' 
and $briks-components__grid__name != false 
and $briks-components__grid__aspect-ratios-prefix != false 
and length($briks-components__grid__aspect-ratios-names-and-values) > 0 {
    // Grid component
    .#{$briks-components-prefix}#{$briks-components__grid__name} {
        @each $name, $value in $briks-components__grid__aspect-ratios-names-and-values {
            &.#{$briks-modifiers-prefix}#{$briks-components__grid__aspect-ratios-prefix}#{$name} {
                &>* {
                    &:before {
                        content: '';
                        float: left;
                        pointer-events: none;
                        padding-top: unquote(100 * (1 / $value) + '%');
                    }
                    @if $briks-components__grid__aspect-ratios-item-bypass-name != false
                    and $briks-components__grid__aspect-ratios-item-bypass-name != '' {
                        &.#{$briks-modifiers-prefix}#{$briks-components__grid__aspect-ratios-prefix}#{$briks-components__grid__aspect-ratios-item-bypass-name} {
                            &:before {
                                float: none;
                                padding-top: inherit;
                            }
                        }
                    }
                }
            }
        }
        @if $briks-components__grid__aspect-ratios-responsive {
            @each $screen, $value in $briks-screen-sizes {
                @include my-screen-size($screen) {
                    @each $name, $value in $briks-components__grid__aspect-ratios-names-and-values {
                        &.#{$briks-modifiers-prefix}#{$briks-components__grid__aspect-ratios-prefix}#{$name}#{$briks-responsive-prefix}#{$screen},
                        &[#{$briks-modifiers-prefix}#{$briks-components__grid__aspect-ratios-prefix}#{$name}*="#{$screen}"] {
                            &>* {
                                &:before {
                                    content: '';
                                    float: left;
                                    pointer-events: none;
                                    padding-top: unquote(100 * (1 / $value) + '%');
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}