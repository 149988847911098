.disabled,
.#{$briks-utilities-prefix}disabled {
    pointer-events: none;
    opacity: 0.3;
}
// BACKGROUND COVER AND CONTAIN
.#{$briks-utilities-prefix}cover,
.#{$briks-utilities-prefix}contain {
    background-repeat: no-repeat !important;
    background-position: center !important;
}

// PLAYGROUND
.playground {
    display: none;
}
[data-playground-commands] {
    &+p {
        margin-top: 0;
    }
}

// SIDEBAR
#libdoc-sidebar-item-search {
    &+.libdoc-sidebar-item {
        border-top: my-border(thin-dashed-alt);
    }
}

// GFM
.task-list-item {
    input {
        margin-right: my-spacing(sm);
    }
}

// LibDoc Content
main {
    // KRAMDOWN TOC
    &:not(#libdoc-page-split-container) {
        #markdown-toc {
            padding-left: my-spacing(sm);
            &+* {
                margin-top: 0px;
            }
        }
        @include my-screen-size(md,xl) {
            #markdown-toc {
                display: none;
            }
        }
    }
    h1:not(:first-of-type),
    h2,
    h3,
    h4,
    h5,
    h6 { 
        margin-top: 2em;
    }
}
#libdoc-content {
    &>* {
        &:first-child {
            margin-top: 0px;
        }
    }
    ul,
    ol {
        list-style: none;
        ul,
        ol {
            margin-top: 1em;
        }
    }
    ul:not([data-playground-commands]):not(#libdoc-assets) {
        &>li {
            position: relative;
            margin-bottom: 1em;
            &::before {
                content: '';
                position: absolute;
                top: 0.4em;
                right: calc(100% + 0.5em);
                display: block;
                width: 0.7em;
                height: 0.7em;
                border-radius: 100px;
                background-color: my-color(primary);
            }
        }
    }
    ol:not([data-playground-commands]) {
        counter-reset: count;
        &>li:not([data-playground-commands]) {
            counter-increment: count;
            position: relative;
            margin-bottom: 1em;
            &::before {
                content: counters(count, '.');
                position: absolute;
                right: calc(100% + 0.5em);
                padding-right: 0.5em;
                border-right: my-border(large-solid);
                font-size: 1em;
                font-family: my-font-family(headline);
            }
        }
    }
}
#markdown-toc {
    margin-top: 0px;
}
@media print {
    #libdoc-sidebar {
        display: none;
    }
}